export const GET_SUBSCRIPTIONS_BEGIN = "get_subscriptions_begin";
export const GET_SUBSCRIPTIONS_REQUESTING = "get_subscriptions_requesting";
export const GET_SUBSCRIPTIONS_SUCCESS = "get_subscriptions_success";
export const GET_SUBSCRIPTIONS_ERROR = "get_subscriptions_error";
export const GET_SUBSCRIPTIONS_RESET = "get_subscriptions_reset";
export const GET_SUBSCRIPTION_BEGIN = "get_subscription_begin";
export const GET_SUBSCRIPTION_SUCCESS = "get_subscription_success";
export const GET_SUBSCRIPTION_ERROR = "get_subscription_error";
export const GET_SUBSCRIPTION_RESET = "get_subscription_reset";
export const ADD_SUBSCRIPTION_BEGIN = "add_subscription_begin";
export const ADD_SUBSCRIPTION_SUCCESS = "add_subscription_success";
export const ADD_SUBSCRIPTION_ERROR = "add_subscription_error";
export const ADD_SUBSCRIPTION_RESET = "add_subscription_reset";
export const UPDATE_SUBSCRIPTION_BEGIN = "update_subscription_begin";
export const UPDATE_SUBSCRIPTION_ERROR = "update_subscription_error";
export const UPDATE_SUBSCRIPTION_SUCCESS = "update_subscription_success";
export const UPDATE_SUBSCRIPTION_RESET = "update_subscription_reset";
export const DELETE_SUBSCRIPTION_BEGIN = "delete_subscription_begin";
export const DELETE_SUBSCRIPTION_ERROR = "delete_subscription_error";
export const DELETE_SUBSCRIPTION_SUCCESS = "delete_subscription_success";
export const DELETE_SUBSCRIPTION_RESET = "delete_subscription_reset";
