/*  import react packages */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

/*  import Designing Constant */
import FeatherIcon from 'feather-icons-react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from 'reactstrap';

/*  import consumable API functions */
import {
  getPages,
  updatePages
} from '../../../actions/modules_action/customizations';

/* Define a class Based Component */
class DynamicPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        about_us: '',
        facebook_link: '',
        linkedin_link: '',
        instagram_link: '',
        client_say_about_us: '',
        home_check_your_knowledge: '',
        home_education_courses: '',
        flight_scope_blogs: '',
        flight_scope_gallery: '',
        access_course_anywhere: '',
        home_image_content: EditorState.createEmpty()
      },
      form_success_message: '',
      form_error_message: '',
      access_id: 1,
    };
    this.props.getPages(this.props.token, this.state.access_id);
  }

  // handleChange method is used to handle the user input's when event occured.
  handleChange = event => {
    let attribute = event.target.getAttribute('data-state');
    let value = event.target.value;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: value
      }
    });
  };

  onEditorStateChange = (editorState, boxName) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [boxName]: editorState
      }
    });
  };

  // handleSubmit method is used to handle the form submitation.
  handleSubmit = event => {
    event.preventDefault();
    const form = Object.assign({}, this.state.form);
    form.home_image_content = draftToHtml(
      convertToRaw(form.home_image_content.getCurrentContent())
    );
    this.props.updatePages(
      this.props.token,
      this.state.access_id,
      form
    );
  };

  convertToDraft = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  };

  // START: Component rendering
  render = () => {

    // Used to display records
    if (
      Object.keys(this.props.GetPages).length > 0 &&
      'status' in this.props.GetPages       
    ) {
      if (
        this.props.GetPages.status &&
        Object.keys(this.props.GetPages.data).length > 0
      ) {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            about_us: this.props.GetPages.data[0].content,
            facebook_link: this.props.GetPages.data[1].content,
            instagram_link: this.props.GetPages.data[2].content,
            linkedin_link: this.props.GetPages.data[3].content,
            client_say_about_us: this.props.GetPages.data[4].content,
            home_check_your_knowledge: this.props.GetPages.data[5].content,
            home_education_courses: this.props.GetPages.data[6].content,
            flight_scope_blogs: this.props.GetPages.data[8].content,
            flight_scope_gallery: this.props.GetPages.data[9].content,
            access_course_anywhere: this.props.GetPages.data[10].content,
            home_image_content: this.convertToDraft(
              this.props.GetPages.data[7].content
            )
          }
        });
        this.props.getPages(this.props.token);
      } else {
        this.setState({
          ...this.state,
          form_error_message: 'Something Wrong.'
        });
      }
    }

    // Used to update record.
    if (
      Object.keys(this.props.UpdatePages).length > 0 &&
      'status' in this.props.UpdatePages
    ) {
      if (this.props.UpdatePages.status) {
        if (this.state.form_success_message === '') {
          this.setState({
            ...this.state,
            form_success_message: 'Page Updated Successfully.',
            access_id: 1,
            isFormEditable: false,
            form_error_message: ''
          });
          this.props.getPages(this.props.token, this.state.access_id);
          setTimeout(() => this.setState({form_success_message: ''}), 5000);
        }
      } else {
        this.setState({
          ...this.state,
          form_success_message: '',
          form_error_message: this.props.UpdatePages.message || ''
        });
        setTimeout(() => this.setState({form_error_message: ''}), 5000);
      }
      this.props.updatePages(this.props.token, this.state.access_id);
    }

    return (
      <div className='Discount-form text-medium'>
        <Col>
          {this.state.form_success_message !== '' && (
            <Alert color='success'>
              <strong>Success:</strong> {this.state.form_success_message}
            </Alert>
          )}
          {this.state.form_error_message !== '' && (
            <Alert color='danger'>
              <strong>Error:</strong> {this.state.form_error_message}
            </Alert>
          )}
          <Form id='Discount' onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup row>
                  <Label for='about_us' sm={3}>
                    <b>About Us</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='textarea'
                      name='about_us'
                      data-state='about_us'
                      className='form-control'
                      placeholder='About Us'
                      value={this.state.form.about_us}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='client_say_about_us' sm={3}>
                  <b>Client Say About Us </b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='client_say_about_us'
                      data-state='client_say_about_us'
                      className='form-control'
                      placeholder='Instagram Url'
                      value={this.state.form.client_say_about_us}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='home_image_content' sm={3}>
                  <b>Home Image Banner Content </b>
                  </Label>
                  <Col sm={9}>
                    <Editor
                      editorState={this.state.form.home_image_content}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='textEditorWrapper'
                      editorClassName='textEditor'
                      onEditorStateChange={editorState =>
                        this.onEditorStateChange(editorState, 'home_image_content')
                      }
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                      }}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={6}>
              <FormGroup row>
                  <Label for='facebook_link' sm={3}>
                  <b>Facebook </b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='facebook_link'
                      data-state='facebook_link'
                      className='form-control'
                      placeholder='Facebook Url'
                      value={this.state.form.facebook_link}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='linkedin_link' sm={3}>
                  <b>LinkedIn</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='linkedin_link'
                      data-state='linkedin_link'
                      className='form-control'
                      placeholder='LinkedIn Url'
                      value={this.state.form.linkedin_link}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='instagram_link' sm={3}>
                    <b>Instagram</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='instagram_link'
                      data-state='instagram_link'
                      className='form-control'
                      placeholder='Instagram Url'
                      value={this.state.form.instagram_link}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='flight_scope_blogs' sm={3}>
                  <b> Flight Scope Blogs</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='flight_scope_blogs'
                      data-state='flight_scope_blogs'
                      className='form-control'
                      placeholder='Flight Scope Blogs'
                      value={this.state.form.flight_scope_blogs}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='flight_scope_gallery' sm={3}>
                  <b>Flight Scope Gallery</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='flight_scope_gallery'
                      data-state='flight_scope_gallery'
                      className='form-control'
                      placeholder='Flight Scope Gallery'
                      value={this.state.form.flight_scope_gallery}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='access_course_anywhere' sm={3}>
                  <b>Access Course Anywhere</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='access_course_anywhere'
                      data-state='access_course_anywhere'
                      className='form-control'
                      placeholder='Access Course Anywhere'
                      value={this.state.form.access_course_anywhere}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for='home_check_your_knowledge' sm={3}>
                  <b>Home Page Check Your Knowledge</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='home_check_your_knowledge'
                      data-state='home_check_your_knowledge'
                      className='form-control'
                      placeholder='Home Page Check Your Knowledge'
                      value={this.state.form.home_check_your_knowledge}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>
                
                <FormGroup row>
                  <Label for='home_education_courses' sm={3}>
                  <b>Home Page Education Courses</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type='text'
                      name='home_education_courses'
                      data-state='home_education_courses'
                      className='form-control'
                      placeholder='Home Page Education Courses'
                      value={this.state.form.home_education_courses}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button
                      color='info btn-auto'
                      className='ml-2 float-right'
                      onClick={this.handleSubmit}
                    >
                      <FeatherIcon icon='save' size='20' className='mb-1' />{' '}
                      Save
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    );
  };
  // END: Component rendering
}

const mapStateToProps = state => {
  return {
    GetPages: state.GetPages,
    UpdatePages: state.UpdatePages
  };
};

export default connect(
  mapStateToProps,
  { getPages, updatePages }
)(DynamicPages);
