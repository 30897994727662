import axios from 'axios';
import { API_URL } from './../../constants';
import { 
  GET_SUBSCRIPTIONS_BEGIN, 
  GET_SUBSCRIPTIONS_SUCCESS, 
  GET_SUBSCRIPTIONS_ERROR,
  GET_SUBSCRIPTION_BEGIN, 
  GET_SUBSCRIPTION_SUCCESS, 
  GET_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION_BEGIN, 
  ADD_SUBSCRIPTION_SUCCESS, 
  ADD_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION_RESET,
  UPDATE_SUBSCRIPTION_BEGIN,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_RESET,
  DELETE_SUBSCRIPTION_BEGIN,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_RESET
} from '../../constants/subscription';

export const getSubscriptions = (id, data = {}) => dispatch => {
  if (id === undefined) {
    dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: {} });
    dispatch({ type: ADD_SUBSCRIPTION_SUCCESS, payload: {} });
    dispatch({ type: GET_SUBSCRIPTION_SUCCESS, payload: {} });
    dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: {} });
    dispatch({ type: DELETE_SUBSCRIPTION_SUCCESS, payload: {} });
    return dispatch({type: GET_SUBSCRIPTIONS_BEGIN,payload: {}});
  }
  axios.get(`${API_URL}/public/subscriptions`, {
    params: data
  })
  .then(response => dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: response.data }))
  .catch(error => dispatch({ type: GET_SUBSCRIPTIONS_ERROR, payload: error.response.data }));
}

export const getSubscription = id => dispatch => {
  if (id === undefined) {
    return dispatch({
      type: GET_SUBSCRIPTION_BEGIN,
      payload: {}
    });
  }
  axios.get(`${API_URL}/public/subscription/${id}`)
  .then(response => dispatch({ type: GET_SUBSCRIPTION_SUCCESS, payload: response.data }))
  .catch(error => dispatch({ type: GET_SUBSCRIPTION_ERROR, payload: error.response }));
}

export const addSubscription = (token, data = {}) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return async dispatch => {
    if (Object.keys(data).length > 0) {
      dispatch({ type:ADD_SUBSCRIPTION_BEGIN, payload: {} });
      await axios.post(`${API_URL}/private/subscription/new`, {...data,isActive:true})
        .then(response => dispatch({ type: ADD_SUBSCRIPTION_SUCCESS, payload: response.data }))
        .catch(error => dispatch({ type: ADD_SUBSCRIPTION_ERROR, payload: error.response.data }));
    } else {
      dispatch({ type: ADD_SUBSCRIPTION_RESET, payload: {} });
    }
  }
}

// Delete Subscription 
export const deleteSubscription = (token, id) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return async dispatch => {
    if (id) {
      dispatch({ type: DELETE_SUBSCRIPTION_BEGIN, payload: {} });
      await axios.delete(`${API_URL}/private/subscription/${id}/delete`)
        .then(response =>dispatch({ type: DELETE_SUBSCRIPTION_SUCCESS,payload: { status: response.data.status}}))
        .catch(error => dispatch({ type: DELETE_SUBSCRIPTION_ERROR, payload: error.response.data }));
    } else {
      dispatch({ type: DELETE_SUBSCRIPTION_RESET, payload: {} });
    }
  }
};

//update subscription
export const updateSubscription = (token, id, data = {}) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return async dispatch => {
    if (Object.keys(data).length > 0) {
      dispatch({ type: UPDATE_SUBSCRIPTION_BEGIN, payload: {} });
      await axios.patch(`${API_URL}/private/subscription/${id}/edit`, data)
        .then(response => dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: response.data }))
        .catch(error => dispatch({ type: UPDATE_SUBSCRIPTION_ERROR, payload: error.response.data }));
    } else {
      dispatch({ type: UPDATE_SUBSCRIPTION_RESET, payload: {} });
    }
  }
}