import {
  GET_SUBSCRIPTIONS_BEGIN,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_ERROR,
  GET_SUBSCRIPTION_BEGIN,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_RESET,
  ADD_SUBSCRIPTION_BEGIN,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION_RESET,
  UPDATE_SUBSCRIPTION_BEGIN,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_RESET,
  DELETE_SUBSCRIPTION_BEGIN,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_RESET
} from "../../constants/subscription";

export const getSubscriptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_BEGIN:
      
    case GET_SUBSCRIPTIONS_SUCCESS:
      
    case GET_SUBSCRIPTIONS_ERROR:
      return Object.assign({}, action.payload);

    default:
      return Object.assign({}, state);
  }
}

export const addSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SUBSCRIPTION_BEGIN:

    case ADD_SUBSCRIPTION_RESET:
      
    case ADD_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, action.payload);

    case ADD_SUBSCRIPTION_ERROR:
      return Object.assign({}, action.payload);

    default:
      return Object.assign({}, state);
  }
}

export const getSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_BEGIN:

    case GET_SUBSCRIPTION_RESET:
      
    case GET_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, action.payload);

    case GET_SUBSCRIPTION_ERROR:
      return Object.assign({}, action.payload);

    default:
      return Object.assign({}, state);
  }
}

export const updateSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_BEGIN:

    case UPDATE_SUBSCRIPTION_RESET:
      
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, action.payload);

    case UPDATE_SUBSCRIPTION_ERROR:
      return Object.assign({}, action.payload);

    default:
      return Object.assign({}, state);
  }
}

export const deleteSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SUBSCRIPTION_BEGIN:

    case DELETE_SUBSCRIPTION_RESET:
      
    case DELETE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, action.payload);

    case DELETE_SUBSCRIPTION_ERROR:
      return Object.assign({}, action.payload);

    default:
      return Object.assign({}, state);
  }
}