import React, { Component } from "react";
import { connect } from "react-redux";
import { getSubscriptions } from "./../../actions/modules_action/subscriptions";
import FeatherIcon from "feather-icons-react";
import {
  Container,
  Row,
  Collapse,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
} from "reactstrap";

class BotomContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      accordionActive: "",
      accordionCollapse: false,
      subscriptions: {}
    };
    this.props.getSubscriptions(true, {isActive: 1});
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  accordionToggle = accordion => {
    let accordionActive = "";
    let accordionCollapse = false;

    if (this.state.accordionActive !== accordion) {
      accordionActive = accordion;
      accordionCollapse = true;
    }

    this.setState({
      ...this.state,
      accordionActive: accordionActive,
      accordionCollapse: accordionCollapse
    });
  };

  renderSubscriptionContent = (key, subscription) => {
    //console.log('subscription', subscription)

    return (<div><Button
      className="accordion-item btn-no-shadow text-left px-4"
      onClick={() => this.accordionToggle(key)}
    >
      <span>
        {subscription.level}  <strong>{subscription.name}</strong>
      </span>
      {this.state.accordionCollapse &&
      this.state.accordionActive === key ? (
        <FeatherIcon
          icon="chevron-down"
          size="26"
          className="ml-4 float-right"
        />
      ) : (
        <FeatherIcon
          icon="chevron-right"
          size="26"
          className="ml-4 float-right"
        />
      )}
      <Button
        color="danger btn-auto float-right"
        href="/#/subscription-plan"
      >
        Enroll
      </Button>
    </Button>
    <Collapse
      isOpen={
        this.state.accordionCollapse &&
        this.state.accordionActive === key
      }
    >
      <Card className="border-0 text-medium">
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: subscription.content.slice(0,355) }} />
          <a
            href={`/#/certification-page/${subscription.id}`}
            className="float-right pointer"
          >
            See More
          </a>
        </CardBody>
      </Card>
    </Collapse></div>);
  }

  render = () => {
    if (
      Object.keys(this.props.GetSubscriptions).length > 0 &&
      "status" in this.props.GetSubscriptions
    ) {
      if (this.props.GetSubscriptions.status) {
        this.setState({subscriptions : this.props.GetSubscriptions.data});
      }
      this.props.getSubscriptions();
    }

    return (
      <Container className="bottom-content mt-5" id="certification">
        <Row className="accordion d-flex flex-column">
          <Nav className="md-tabs" tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => this.toggleTab("1")}
              >
                Certification
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="px-0">
            <TabPane tabId="1">
              <div className="accordion-group">
                {this.state.subscriptions.length > 0 ?
                  this.state.subscriptions.map((subscription, key) =>  
                    this.renderSubscriptionContent(key, subscription)
                  ) : ""}
              </div>
            </TabPane>
          </TabContent>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {

  return {
    GetSubscriptions: state.GetSubscriptions
  };
};

export default connect(
  mapStateToProps,
  { getSubscriptions }
)(BotomContent);