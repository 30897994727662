export const GET_COURSES_BEGIN = "get_courses_begin";
export const GET_COURSES_REQUESTING = "get_courses_requesting";
export const GET_COURSES_SUCCESS = "get_courses_success";
export const GET_COURSES_ERROR = "get_courses_error";

export const GET_COURSE_BEGIN = "get_course_begin";
export const GET_COURSE_REQUESTING = "get_course_requesting";
export const GET_COURSE_SUCCESS = "get_course_success";
export const GET_COURSE_ERROR = "get_course_error";
export const GET_COURSE_RESET = "get_course_reset";

export const SET_COURSE_BEGIN = "set_course_begin";
export const SET_COURSE_REQUESTING = "set_course_requesting";
export const SET_COURSE_SUCCESS = "set_course_success";
export const SET_COURSE_ERROR = "set_course_error";
export const SET_COURSE_RESET = "set_course_reset";

export const UPDATE_COURSE_BEGIN = "update_course_begin";
export const UPDATE_COURSE_REQUESTING = "update_course_requesting";
export const UPDATE_COURSE_SUCCESS = "update_course_success";
export const UPDATE_COURSE_ERROR = "update_course_error";
export const UPDATE_COURSE_RESET = "update_course_reset";

export const DELETE_COURSE_BEGIN = "delete_course_begin";
export const DELETE_COURSE_REQUESTING = "delete_course_requesting";
export const DELETE_COURSE_SUCCESS = "delete_course_success";
export const DELETE_COURSE_ERROR = "delete_course_error";
export const DELETE_COURSE_RESET = "delete_course_reset";

export const GET_COURSES_BY_USER_BEGIN = "get_courses_by_user_begin";
export const GET_COURSES_BY_USER_REQUESTING = "get_courses_by_user_requesting";
export const GET_COURSES_BY_USER_SUCCESS = "get_courses_by_user_success";
export const GET_COURSES_BY_USER_ERROR = "get_courses_by_user_error";
export const GET_COURSES_BY_USER_RESET = "get_courses_by_user_reset";

export const GET_USER_COURSES_DETAILS_BEGIN = "get_user_courses_details_begin";
export const GET_USER_COURSES_DETAILS_REQUESTING =
  "get_user_courses_details_requesting";
export const GET_USER_COURSES_DETAILS_SUCCESS =
  "get_user_courses_details_success";
export const GET_USER_COURSES_DETAILS_ERROR = "get_user_courses_details_error";
export const GET_USER_COURSES_DETAILS_RESET = "get_user_courses_details_reset";

export const UPDATE_ACTIVITY_PROGRESS_BEGIN = "update_activity_progress_begin";
export const UPDATE_ACTIVITY_PROGRESS_REQUESTING =
  "update_activity_progress_requesting";
export const UPDATE_ACTIVITY_PROGRESS_SUCCESS =
  "update_activity_progress_success";
export const UPDATE_ACTIVITY_PROGRESS_ERROR = "update_activity_progress_error";
export const UPDATE_ACTIVITY_PROGRESS_RESET = "update_activity_progress_reset";

export const GET_FEEDBACK_BEGIN = "get_feedback_begin";
export const GET_FEEDBACK_REQUESTING = "get_feedback_requesting";
export const GET_FEEDBACK_SUCCESS = "get_feedback_success";
export const GET_FEEDBACK_ERROR = "get_feedback_error";
export const GET_FEEDBACK_RESET = "get_feedback_reset";

export const SAVE_USER_FEEDBACK_BEGIN = "save_user_feedback_begin";
export const SAVE_USER_FEEDBACK_REQUESTING = "save_user_feedback_requesting";
export const SAVE_USER_FEEDBACK_SUCCESS = "save_user_feedback_success";
export const SAVE_USER_FEEDBACK_ERROR = "save_user_feedback_error";
export const SAVE_USER_FEEDBACK_RESET = "save_user_feedback_reset";

export const GET_USER_COURSE_FEEDBACK_DETAILS_BEGIN =
  "get_user_course_feedback_details_begin";
export const GET_USER_COURSE_FEEDBACK_DETAILS_REQUESTING =
  "get_user_course_feedback_details_requesting";
export const GET_USER_COURSE_FEEDBACK_DETAILS_SUCCESS =
  "get_user_course_feedback_details_success";
export const GET_USER_COURSE_FEEDBACK_DETAILS_ERROR =
  "get_user_course_feedback_details_error";
export const GET_USER_COURSE_FEEDBACK_DETAILS_RESET =
  "get_user_course_feedback_details_reset";

export const GET_TRAINER_COURSES_BEGIN = "get_trainer_courses_begin";
export const GET_TRAINER_COURSES_REQUESTING = "get_trainer_courses_requesting";
export const GET_TRAINER_COURSES_SUCCESS = "get_trainer_courses_success";
export const GET_TRAINER_COURSES_ERROR = "get_trainer_courses_error";
export const GET_TRAINER_COURSES_RESET = "get_trainer_courses_reset";
