import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getSubscriptions } from "./../../actions/modules_action/subscriptions";
import { Container, Col, Button } from 'reactstrap';
import { connect } from "react-redux";

class Certification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: {}
    };
    this.props.getSubscriptions(true, {isActive: 1});
  }

  renderCertificateContent = (id, subscriptions) => {
    
    var content = '';
    subscriptions.filter(item => item.id == id).map((value) =>{

      content = <Col>
        <h1 className="display-4">
        {value.level} <strong>{value.name}</strong> - ${value.price}
          <Button color="danger btn-auto float-right" href="/#/subscription-plan">Buy Subscription</Button>
        </h1>
        <div className="text-large mt-4 mb-4">
          <div dangerouslySetInnerHTML={{ __html: value.content }} />
        </div>
      </Col>;
    });

    return content ? content : <Redirect to="" />;
  }

  render = () => {

    if (
      Object.keys(this.props.GetSubscriptions).length > 0 &&
      "status" in this.props.GetSubscriptions
    ) {
      if (this.props.GetSubscriptions.status) {
        this.setState({subscriptions : this.props.GetSubscriptions.data});
      }
      this.props.getSubscriptions();
    }

    return (
      <Container className="mt-5 pb-5 border-bottom">
        { this.state.subscriptions.length > 0 ? this.renderCertificateContent(this.props.certificate, this.state.subscriptions) : ''}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    GetSubscriptions: state.GetSubscriptions
  };
};

export default connect(
  mapStateToProps,
  { getSubscriptions }
)(Certification);