import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Input, Button, Col, UncontrolledAlert } from 'reactstrap';

import './style.css';
import background from './../../images/background.jpg';
import { getGeneral } from '../../actions/modules_action/customizations';
import { API_URL } from './../../constants';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: ''
      },
      accessId: 1,
    };
    this.props.getGeneral(this.props.token, this.state.accessId);
  }

  handleChange = (event) => {
    let attribute = event.target.getAttribute('data-state');

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: event.target.value
      }
    });
  }

  handleMessage = () => {
    if(this.props.error !== '') {
      return (
        <Col>
          <UncontrolledAlert className="text-left" color="danger">
            <strong>Error:</strong> {this.props.error}
          </UncontrolledAlert>
        </Col>
      );
    } else if(this.props.success !== '') {
      return (
        <Col>
          <UncontrolledAlert className="text-left" color="success ">
            <strong>Success:</strong> {this.props.success}
          </UncontrolledAlert>
        </Col>
      );
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.submit(this.state.form);
  }
    
  render = () => {

    if (
      Object.keys(this.props.GetGeneral).length > 0 &&
      'status' in this.props.GetGeneral
    ) {
      if (
        this.props.GetGeneral.status &&
        Object.keys(this.props.GetGeneral.data).length > 0
      ) {
        this.setState({
          ...this.state,
          logo_image: this.props.GetGeneral.data.logo_image,
        });
        this.props.getGeneral();
      }
    }
    return (
    <div id="forgot-password-page" style={{ backgroundImage: `url(${background})` }}>
      <Container>
        <div className="form-heading text-center mt-5 mb-4">
          <a href="/#/"><img src={`${API_URL}/${this.state.logo_image}`} alt="Logo" style={{width:'555px'}} /></a>
        </div>
        <div className="forgot-password-form  text-center">
          <Col>
            <h1 className="display-4">Forgot Your Password?</h1>
            <p className="callout text-large text-left">
              Please enter your email address. You will receive a recovery link to create a new password via email.
            </p>
          </Col>
          {this.handleMessage()}
          <Col>
            <Form id="forgot-password" onSubmit={this.handleSubmit}>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  data-state="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={this.state.form.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
                <Button color="info">Send Me Recovery Link</Button>
            </Form>
            <div className="card-footer mt-3 text-medium">
              Back to <a href="/#/login">Login</a>
            </div>
          </Col>
        </div>
      </Container>
    </div>
    )
  };
}

const mapStateToProps = state => {
  return {
    GetGeneral : state.GetGeneral
  };
}

export default connect(
  mapStateToProps, 
  { getGeneral }
)(ForgotPassword);