import React, { Component } from "react";

import CertificationPage from "./../../components/home/certification_page";

export default class CertificationPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render = () => <CertificationPage certificate={this.props.match.params.id} />;
}
