/*  import react packages */
import React from "react";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

/*  import Designing Constant */
import FeatherIcon from "feather-icons-react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import isEmpty from '../../../validation/is-empty';

/*  import a javascript file */

/*  import consumable API functions */
import {
  getPages,
  updatePages
} from "../../../actions/modules_action/customizations";
import { getSubscriptions, 
  getSubscription,
  addSubscription, 
  updateSubscription, 
  deleteSubscription } from "../../../actions/modules_action/subscriptions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { API_URL } from '../../../constants';

/* Define a class Based Component */
class SubscriptionsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        level: "",
        name: "",
        price: "",
        backgroundColor: "",
        badgeColor: "",
        content: EditorState.createEmpty()
      },
      uploadProperty: "",
      formSuccessMessage: "",
      formErrorMessage: "",
      accessId: 1,
      uploadError: "",
      subscriptions: {},
      isEditActive:false,
      isModalOpen:false,
      modalData:{},
      errors:{}
    };
    this.props.getSubscriptions(true);
    this.props.getPages(this.props.token, this.state.accessId);
  }

  viewModal = async (id) => {
    this.setState({isModalOpen: !this.state.isModalOpen});

    let res = await fetch(`${API_URL}/public/subscription/${id}`);
    let data = await res.json();
    
    if (data.status){
      this.setState({modalData: data.data});
    }
  };

  validate = (values) => {
    let errors = {};
    if ( values.price === undefined || values.price === "") {
      errors.price = 'Subscription price is required.';
    // } else if (values.price && !/^[1-9]?[0-9]{1}$|^100000/.test(values.price)) {
    } else if (values.price && !/^(\d+\.?\d{0,2}|\.\d{1,9})$/.test(values.price)) {
      errors.price = 'Only Intergers are allowed.';
    }
    if (values.name === undefined || values.name === "") {
      errors.name = 'Subscription name is required.';
    }
    if (values.level === undefined || values.level === "") {
      errors.level = 'Subscription level is required.';
    }
    if (values.backgroundColor === undefined || values.backgroundColor === "") {
      errors.backgroundColor = 'Please select a Background Color.';
    }
    if (values.badgeColor === undefined || values.badgeColor === "") {
      errors.badgeColor = 'Please select a Badge Color.';
    }
    // if (values.content === undefined || values.content === "") {
    //   errors.content = 'Subscription content is required.';
    // }
    return errors;
  }

  // handleChange method is used to handle the user input's when event occured.
  handleChange = event => {
    let attribute = event.target.getAttribute("data-state");
    let value = event.target.value;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: value
      }
    });
  };

  onEditorStateChange = (editorState, boxName) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [boxName]: editorState
      }
    });
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      isEditActive: false,
      form: { level: "", name: "", price: "", backgroundColor: "", badgeColor: "", content: EditorState.createEmpty()
      }
    });
  }

  // handleSubmit method is used to handle the form submitation.
  handleSubmit = async (event) => {
    event.preventDefault();
    const form = Object.assign({}, this.state.form);
    form.price = Number(form.price);
    form.content  = draftToHtml(
      convertToRaw(form.content .getCurrentContent())
    );

    await this.setState({}, ()=>{this.setState({errors: this.validate(this.state.form)})});

    if ( this.state.isEditActive && Object.keys(this.state.errors).length === 0){
      this.props.updateSubscription(
        this.props.token, this.state.isEditActive, form
      );
    } else if (Object.keys(this.state.errors).length === 0) {
      this.props.addSubscription(
        this.props.token, form
      );
    }
  };

  convertToDraft = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  };

  updateSubscriptionStatus = (id, status) => {
    this.props.updateSubscription(this.props.token, id, { isActive: status });
  };
  handleDeleteSubscription = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        { label: 'Yes',
          onClick: () => this.props.deleteSubscription(this.props.token, id)
        },
        { label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  handleEditSubscription = (id) => {
    this.props.getSubscription(id);
  };

  // renderAction method is used to render Edit and Delete Icon in the ui
  renderAction = (id, isActive ) => (
    <div className="actions">
      <Button color={isActive ? "success" : "danger"} data-id={id} className="btn-auto pt-1 pb-1 px-1 mr-2" onClick={e => this.updateSubscriptionStatus(id, !isActive)}>
          <FeatherIcon icon={`${isActive ? 'toggle-right' : 'toggle-left'}`} size="18" className="mb-1" />
      </Button>
      <Button color="warning" data-id={id} className="btn-auto pt-1 pb-1 px-1 mr-2" onClick={() => this.viewModal(id)}>
          <FeatherIcon icon="eye" size="18" className="mb-1" />
      </Button>
      <Button color="info" data-id={id} className="btn-auto pt-1 pb-1 px-1 mr-2" onClick={() => this.handleEditSubscription(id)}>
          <FeatherIcon icon="edit" size="18" className="mb-1" />
      </Button>
      <Button color="danger" data-id={id} className="btn-auto pt-1 pb-1 px-1" onClick={() => this.handleDeleteSubscription(id)}>
          <FeatherIcon icon="trash-2" size="18" className="mb-1" />
      </Button>
    </div>
  );

  renderSubscriptions = subscription => {
    return (
      <tr key={subscription.id}>
        <td>{subscription.name}</td>
        <td>{subscription.price}</td>
        <td>
          {this.renderAction(subscription.id, subscription.isActive )}
        </td>
      </tr>
    );
  };

  // START: Component rendering
  render = () => {
    const errorStyle = {
      color: 'red',
      fontSize: '13px',
    };
    if (
      Object.keys(this.props.GetSubscription).length > 0 &&
      "status" in this.props.GetSubscription
    ) {

      this.setState({
        ...this.state,
        form: { level: this.props.GetSubscription.data.level, name: this.props.GetSubscription.data.name, price: this.props.GetSubscription.data.price, backgroundColor: this.props.GetSubscription.data.backgroundColor, badgeColor: this.props.GetSubscription.data.badgeColor, content: this.convertToDraft(
          this.props.GetSubscription.data.content
         )
        },
        isEditActive:this.props.GetSubscription.data.id,
        errors:{}
      });
      this.props.getSubscription();
    }
    
    // Used to display records
    if (
      Object.keys(this.props.GetSubscriptions).length > 0 &&
      "status" in this.props.GetSubscriptions       
    ) {
      if (
        this.props.GetSubscriptions.status &&
        Object.keys(this.props.GetSubscriptions.data).length > 0
      ) {
        this.setState({
          subscriptions:this.props.GetSubscriptions.data
        });
        this.props.getSubscriptions();
      } else {
        this.setState({
          formErrorMessage: "Something Wrong."
        });
      }
    }

    if (
      Object.keys(this.props.UpdateSubscription).length > 0 &&
      "status" in this.props.UpdateSubscription
    ) {
      this.props.updateSubscription();
      if (this.props.UpdateSubscription.status){
        this.setState({
          ...this.state,
          formSuccessMessage: "Subscription plan has been updated successfully",
          form: { level: "", name: "", price: "", backgroundColor: "", badgeColor: "", content:     EditorState.createEmpty(),
          },
          isEditActive: false
        });
        setTimeout(() => this.setState({formSuccessMessage: ''}), 5000);
        this.props.getSubscriptions(true);
      } else if (this.props.UpdateSubscription.status === false){
        this.setState({
          ...this.state,
          formErrorMessage: this.props.UpdateSubscription.message
        });
        setTimeout(() => this.setState({formErrorMessage: ''}), 7000);
      }
    }

    if ( "status" in this.props.DeleteSubscription ) {
      this.props.deleteSubscription();
      this.setState({
        formSuccessMessage: "Subscription plan has been deleted successfully",
        form: { level: "", name: "", price: "", backgroundColor: "", badgeColor: "", content:     EditorState.createEmpty(),
        },
        isEditActive: false
      });
      setTimeout(() => this.setState({formSuccessMessage: ''}), 5000);
      this.props.getSubscriptions(true);
    }

    if (
      Object.keys(this.props.AddSubscription).length > 0 &&
      "status" in this.props.AddSubscription
    ) {
      this.props.addSubscription();
      if (this.props.AddSubscription.status){
        this.setState({
          ...this.state,
          formSuccessMessage: "Subscription plan has been added successfully",
          form: { level: "", name: "", price: "", backgroundColor: "", badgeColor: "", content: EditorState.createEmpty()
          }
        });
        setTimeout(() => this.setState({formSuccessMessage: ''}), 5000);
        this.props.getSubscriptions(true);
      } else if (this.props.AddSubscription.status === false){
        this.setState({
          ...this.state,
          formErrorMessage: this.props.AddSubscription.message
        });
        setTimeout(() => this.setState({formErrorMessage: ''}), 7000);
      }
    }

    return (
      <div className="Discount-form text-medium">
        <Col>
          {this.state.formSuccessMessage !== "" && (
            <UncontrolledAlert className="text-left" color="success">
              <strong>Success:</strong> {this.state.formSuccessMessage}
            </UncontrolledAlert>
          )}
          {this.state.formErrorMessage !== "" && (
            <UncontrolledAlert className="text-left" color="danger">
              <strong>Error:</strong> {this.state.formErrorMessage}
            </UncontrolledAlert>
          )}
          <Form id="Discount" onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup row>
                  <Label for="level" sm={3}>
                    <b>Level</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="level"
                      data-state="level"
                      className="form-control"
                      placeholder="eg: Start Level"
                      value={this.state.form.level}
                      onChange={this.handleChange}
                    />
                    {this.state.errors.level && ( <p style={errorStyle}>{this.state.errors.level}</p>)}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="name" sm={3}>
                  <b>Name</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="name"
                      data-state="name"
                      className="form-control"
                      placeholder="eg: Annual Membership"
                      value={this.state.form.name}
                      onChange={this.handleChange}
                    />
                     {this.state.errors.name && ( <p style={errorStyle}>{this.state.errors.name}</p>)}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="price" sm={3}>
                  <b>Price</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="price"
                      data-state="price"
                      className="form-control"
                      placeholder="eg: 12.34"
                      value={this.state.form.price}
                      onChange={this.handleChange}
                    />
                     {this.state.errors.price && ( <p style={errorStyle}>{this.state.errors.price}</p>)}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>
                  <b>Background Color</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="backgroundColor"
                      data-state="backgroundColor"
                      className="form-control"
                      value={this.state.form.backgroundColor}
                      onChange={this.handleChange}
                    >
                      <option value="">Select A Background Color</option>
                      <option value="blue">Blue</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="green">Green</option>
                    </Input>
                    {this.state.errors.backgroundColor && ( <p style={errorStyle}>{this.state.errors.backgroundColor}</p>)}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>
                  <b>Badge Color</b>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="badgeColor"
                      data-state="badgeColor"
                      className="form-control"
                      value={this.state.form.badgeColor}
                      onChange={this.handleChange}
                    ><option value="">Select A Badge Color</option>
                    <option value="info">Info</option>
                    <option value="warning">Warning</option>
                    <option value="danger">Danger</option>
                    <option value="success">Success</option>
                  </Input>
                  {this.state.errors.badgeColor && ( <p style={errorStyle}>{this.state.errors.badgeColor}</p>)}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="content" sm={2}>
                  <b>Content</b>
                  </Label>
                  <Col sm={10}>
                    <Editor
                      editorState={this.state.form.content}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="textEditorWrapper"
                      editorClassName="textEditor"
                      onEditorStateChange={editorState =>
                        this.onEditorStateChange(editorState, 'content')
                      }
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                <Col sm={6}>
                  {this.state.isEditActive ? 
                    <Button
                      color="danger btn-auto"
                      className="ml-2 float-right"
                      onClick={this.handleCancel}
                    >
                      <FeatherIcon icon="x" size="20" className="mb-1" />{" "}
                      Cancel
                    </Button>
                    :""}
                  </Col>
                  <Col sm={6}>
                    <Button
                      color="info btn-auto"
                      className="ml-2 float-right"
                      onClick={this.handleSubmit}
                    >
                      <FeatherIcon icon="save" size="20" className="mb-1" />{" "}
                      Save
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="30%">Name</th>
                      <th width="25%">Price</th>
                      <th width="45%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(this.state.subscriptions) &&
                      this.state.subscriptions.map(subscription => this.renderSubscriptions(subscription))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Form>
        </Col>
      <Modal isOpen={this.state.isModalOpen} toggle={() => this.viewModal()} className={'modal-lg'}>
        <ModalHeader toggle={() => this.viewModal()}>Subscription Plan</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}> <b>Level:</b></Col>
            <Col sm={9}>{this.state.modalData.level} </Col><br /><br />
            <Col sm={3}> <b>Name:</b></Col>
            <Col sm={9}>{this.state.modalData.name} </Col><br /><br />
            <Col sm={3}> <b>Price:</b></Col>
            <Col sm={9}>{this.state.modalData.price} </Col><br /><br />
            <Col sm={3}> <b>Badge Color:</b></Col>
            <Col sm={9}>{this.state.modalData.badgeColor} </Col><br /><br />
            <Col sm={3}> <b>Background Color:</b></Col>
            <Col sm={9}>{this.state.modalData.backgroundColor} </Col><br /><br />
            <Col sm={3}> <b>Content:</b></Col>
          <Col sm={9}><div dangerouslySetInnerHTML={{ __html: this.state.modalData.content }} /> </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.viewModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
      </div>
    );
  };
  // END: Component rendering
}

const mapStateToProps = state => {
  return {
    GetSubscription: state.GetSubscription,
    GetSubscriptions: state.GetSubscriptions,
    AddSubscription: state.AddSubscription,
    UpdateSubscription: state.UpdateSubscription,
    DeleteSubscription: state.DeleteSubscription,
  };
};

export default connect(
  mapStateToProps,
  { getSubscriptions, getSubscription, addSubscription, updateSubscription, deleteSubscription, updatePages, getPages }
)(SubscriptionsForm);
