import React, { Component } from "react";

import { Row, Col } from "reactstrap";

import "./style.css";

export default class Footer extends React.Component {
  render = () => (
    <footer>
      <Row>
        <Col>
          <p className="text-small text-right mt-2 mr-2">
            Flight Scope University @ 2019 - 2020
          </p>
        </Col>
      </Row>
    </footer>
  );
}
