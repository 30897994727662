export const GET_ACTIVITIES_BEGIN = "get_activities_begin";
export const GET_ACTIVITIES_REQUESTING = "get_activities_requesting";
export const GET_ACTIVITIES_SUCCESS = "get_activities_success";
export const GET_ACTIVITIES_ERROR = "get_activities_error";

export const GET_ACTIVITY_BEGIN = "get_activity_begin";
export const GET_ACTIVITY_REQUESTING = "get_activity_requesting";
export const GET_ACTIVITY_SUCCESS = "get_activity_success";
export const GET_ACTIVITY_ERROR = "get_activity_error";
export const GET_ACTIVITY_RESET = "get_activity_reset";

export const UPLOAD_FILE_BEGIN = "upload_file_begin";
export const UPLOAD_FILE_REQUESTING = "upload_file_requesting";
export const UPLOAD_FILE_SUCCESS = "upload_file_success";
export const UPLOAD_FILE_ERROR = "upload_file_error";
export const UPLOAD_FILE_RESET = "upload_file_reset";

export const SET_ACTIVITY_BEGIN = "set_activity_begin";
export const SET_ACTIVITY_REQUESTING = "set_activity_requesting";
export const SET_ACTIVITY_SUCCESS = "set_activity_success";
export const SET_ACTIVITY_ERROR = "set_activity_error";
export const SET_ACTIVITY_RESET = "set_activity_reset";

export const UPDATE_ACTIVITY_BEGIN = "update_activity_begin";
export const UPDATE_ACTIVITY_REQUESTING = "update_activity_requesting";
export const UPDATE_ACTIVITY_SUCCESS = "update_activity_success";
export const UPDATE_ACTIVITY_ERROR = "update_activity_error";
export const UPDATE_ACTIVITY_RESET = "update_activity_reset";

export const DELETE_ACTIVITY_BEGIN = "delete_activity_begin";
export const DELETE_ACTIVITY_REQUESTING = "delete_activity_requesting";
export const DELETE_ACTIVITY_SUCCESS = "delete_activity_success";
export const DELETE_ACTIVITY_ERROR = "delete_activity_error";
export const DELETE_ACTIVITY_RESET = "delete_activity_reset";

export const SET_SAVE_ASSESSMENT_DETAILS_BEGIN =
  "set_save_assessment_details_begin";
export const SET_SAVE_ASSESSMENT_DETAILS_REQUESTING =
  "set_save_assessment_details_requesting";
export const SET_SAVE_ASSESSMENT_DETAILS_SUCCESS =
  "set_save_assessment_details_success";
export const SET_SAVE_ASSESSMENT_DETAILS_ERROR =
  "set_save_assessment_details_error";
export const SET_SAVE_ASSESSMENT_DETAILS_RESET =
  "set_save_assessment_details_reset";

export const SET_GET_ASSESSMENT_DETAILS_BEGIN =
  "set_get_assessment_details_begin";
export const SET_GET_ASSESSMENT_DETAILS_SUCCESS =
  "set_get_assessment_details_success";
export const SET_GET_ASSESSMENT_DETAILS_ERROR =
  "set_get_assessment_details_error";
export const SET_GET_ASSESSMENT_DETAILS_RESET =
  "set_get_assessment_details_reset";

export const DELETE_ASSESSMENT_BEGIN = "delete_assessment_begin";
export const DELETE_ASSESSMENT_REQUESTING = "delete_assessment_requesting";
export const DELETE_ASSESSMENT_SUCCESS = "delete_assessment_success";
export const DELETE_ASSESSMENT_ERROR = "delete_assessment_error";
export const DELETE_ASSESSMENT_RESET = "delete_assessment_reset";

export const GET_ASSESSMENT_BY_ID_BEGIN = "get_assessment_by_id_begin";
export const GET_ASSESSMENT_BY_ID_SUCCESS = "get_assessment_by_id_success";
export const GET_ASSESSMENT_BY_ID_ERROR = "get_assessment_by_id_error";
export const GET_ASSESSMENT_BY_ID_RESET = "get_assessment_by_id_reset";

export const UPDATE_ASSESSMENT_DETAILS_BEGIN =
  "update_assessment_details_begin";
export const UPDATE_ASSESSMENT_DETAILS_REQUESTING =
  "update_assessment_details_requesting";
export const UPDATE_ASSESSMENT_DETAILS_SUCCESS =
  "update_assessment_details_success";
export const UPDATE_ASSESSMENT_DETAILS_ERROR =
  "update_assessment_details_error";
export const UPDATE_ASSESSMENT_DETAILS_RESET =
  "update_assessment_details_reset";

export const GET_TEST_ACTIVITY_BEGIN = "get_test_activity_begin";
export const GET_TEST_ACTIVITY_REQUESTING = "get_test_activity_requesting";
export const GET_TEST_ACTIVITY_SUCCESS = "get_test_activity_success";
export const GET_TEST_ACTIVITY_ERROR = "get_test_activity_error";
export const GET_TEST_ACTIVITY_RESET = "get_test_activity_reset";

export const SET_SUBMIT_TEST_ASSESSMENT_BEGIN =
  "set_submit_test_assessment_begin";
export const SET_SUBMIT_TEST_ASSESSMENT_REQUESTING =
  "set_submit_test_assessment_requesting";
export const SET_SUBMIT_TEST_ASSESSMENT_SUCCESS =
  "set_submit_test_assessment_success";
export const SET_SUBMIT_TEST_ASSESSMENT_ERROR =
  "set_submit_test_assessment_error";
export const SET_SUBMIT_TEST_ASSESSMENT_RESET =
  "set_submit_test_assessment_reset";

export const SET_SAVE_FEEDBACK_DETAILS_BEGIN =
  "set_save_feedback_details_begin";
export const SET_SAVE_FEEDBACK_DETAILS_REQUESTING =
  "set_save_feedback_details_requesting";
export const SET_SAVE_FEEDBACK_DETAILS_SUCCESS =
  "set_save_feedback_details_success";
export const SET_SAVE_FEEDBACK_DETAILS_ERROR =
  "set_save_feedback_details_error";
export const SET_SAVE_FEEDBACK_DETAILS_RESET =
  "set_save_feedback_details_reset";

export const SET_GET_FEEDBACK_DETAILS_BEGIN = "set_get_feedback_details_begin";
export const SET_GET_FEEDBACK_DETAILS_SUCCESS =
  "set_get_feedback_details_success";
export const SET_GET_FEEDBACK_DETAILS_ERROR = "set_get_feedback_details_error";
export const SET_GET_FEEDBACK_DETAILS_RESET = "set_get_feedback_details_reset";

export const DELETE_FEEDBACK_BEGIN = "delete_feedback_begin";
export const DELETE_FEEDBACK_REQUESTING = "delete_feedback_requesting";
export const DELETE_FEEDBACK_SUCCESS = "delete_feedback_success";
export const DELETE_FEEDBACK_ERROR = "delete_feedback_error";
export const DELETE_FEEDBACK_RESET = "delete_feedback_reset";

export const GET_FEEDBACK_BY_ID_BEGIN = "get_feedback_by_id_begin";
export const GET_FEEDBACK_BY_ID_SUCCESS = "get_feedback_by_id_success";
export const GET_FEEDBACK_BY_ID_ERROR = "get_feedback_by_id_error";
export const GET_FEEDBACK_BY_ID_RESET = "get_feedback_by_id_reset";

export const UPDATE_FEEDBACK_DETAILS_BEGIN = "update_feedback_details_begin";
export const UPDATE_FEEDBACK_DETAILS_REQUESTING =
  "update_feedback_details_requesting";
export const UPDATE_FEEDBACK_DETAILS_SUCCESS =
  "update_feedback_details_success";
export const UPDATE_FEEDBACK_DETAILS_ERROR = "update_feedback_details_error";
export const UPDATE_FEEDBACK_DETAILS_RESET = "update_feedback_details_reset";

export const SAVE_USER_FEEDBACK_BEGIN = "save_user_feedback_begin";
export const SAVE_USER_FEEDBACK_REQUESTING = "save_user_feedback_requesting";
export const SAVE_USER_FEEDBACK_SUCCESS = "save_user_feedback_success";
export const SAVE_USER_FEEDBACK_ERROR = "save_user_feedback_error";
export const SAVE_USER_FEEDBACK_RESET = "save_user_feedback_reset";
