/*  import react packages */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ExportToCsv } from 'export-to-csv';
import CoursesUserList from "./courses_user_list";

/*  import Designing Constant */
import { Button, UncontrolledAlert, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

/*  import consumable API functions */
import { getUsers } from '../../actions/modules_action/users';
import { getCoursesByRevenue } from '../../actions/modules_action/courses';
import isEmpty from '../../validation/is-empty';
const moment = require('moment');

/*  define config variables */
const usersColumns = [{
  dataField: 'firstName',
  text: 'First Name',
  sort: true,
  headerStyle: {
    width: '150px'
  }
}, {
  dataField: 'lastName',
  text: 'Last Name',
  sort: true,
  headerStyle: {
    width: '150px'
  }
}, {
  dataField: 'email',
  text: 'Email',
  sort: true,
  headerStyle: {
    width: '200px'
  }
}, {
  dataField: 'lastLogin',
  text: 'Last Login',
  headerStyle: {
    width: '150px'
  }
}, {
  dataField: 'status',
  text: 'Status',
  headerStyle: {
    width: '100px'
  }
}];
/*  define config variables */
const coursesColumns = [{
  dataField: 'id', 
  text: 'Course Id',
  headerStyle: {
    width: '60px'
  }
}, {
  dataField: 'name',
  text: 'Course Name',
  sort: true,
  headerStyle: {
    width: '150px'
  }
}, {
  dataField: 'coursePrice',
  text: 'Course Price ($)',
  sort: true,
  headerStyle: {
    width: '100px'
  }
}, {
  dataField: 'createdAt',
  text: 'Created At',
  headerStyle: {
    width: '150px'
  }
}, {
  dataField: 'status',
  text: 'Status',
  headerStyle: {
    width: '40px'
  }
}];

/*  define class based component */
class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        activeTab: '1',
        formSuccessMessage: '',
        formErrorMessage: '',
        accessId: 0,
        showLock: false,
        chkbox: true,
        userListEnable: false,
        userListItem: 0,
    }
    this.props.getUsers(this.props.token);
    this.props.getCoursesByRevenue(this.props.token);
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  userListToggle = (enable = false, id = 0) => {
    this.setState({
      ...this.state,
      formSuccessMessage: "",
      userListItem: id,
      userListEnable: enable
    });
  };

  renderUsersRecords = (users, status = true) => {
    return users.map(item => {
      if( this.state.chkbox === false ) {  
        if( item.isActive == true ) {
          return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            lastLogin: item.lastLogin? moment(item.lastLogin).format("ddd, MMMM Do YYYY, h:mm:ss a") : '',
            status: item.isActive ? 'Active' : 'Locked'
          }
        }
      } else if ( this.state.chkbox === true ) {
        return {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          lastLogin: item.lastLogin? moment(item.lastLogin).format("ddd, MMMM Do YYYY, h:mm:ss a") : '',
          status: item.isActive ? 'Active' : 'Locked'
        }
      }
    });
  }

  renderCoursesRecords = (courses, status = true) => {
    return courses.map(course => {
      return {
        id: course.id,
        coursePrice: course.UserCourses.length > 0 ? course.UserCourses[0].revenue : 'Not Subscribed',
        name: course.name,
        createdAt: course.createdAt? moment(course.createdAt).format("ddd, MMMM Do YYYY, h:mm:ss a") : '',
        status: <Button color="success" className="float-right btn-auto" style={{padding:'2px', margin:'2px'}} onClick={() => this.userListToggle(true, course.id)}>
        <FeatherIcon icon="eye" size="20" className="mb-1" />
      </Button>
      }
    });
  }

  userListModal = () => {
    return (
      <CoursesUserList
        token={this.props.token}
        courseId={this.state.userListItem}
        isActive={this.state.userListEnable}
        onCancel={this.userListToggle}
      />
    );
  };

  handleExportUsers = (users) => {
    if(!isEmpty(users)){

      const newUsers = Array.from(users)
        .map(val => [val['firstName'], val['lastName'], val['email'], val['lastLogin'], val['status']]);

      const options = { 
        fieldSeparator: ',',
        filename: 'Ballflight-users-list',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: 'Ballflight University Users',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Firstname', 'Lastname', 'Email', 'Last Login', 'Status']
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(newUsers);
    }
  }

  handleExportCourses = (courses) => {
    if(!isEmpty(courses)){

      const newCourses = Array.from(courses)
        .map(val => [val['id'], val['name'], val['coursePrice'], val['createdAt']]);

      const options = { 
        fieldSeparator: ',',
        filename: 'Ballflight-courses-purchased',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: 'Ballflight University Courses Purchased',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Course Id', 'Course Name', 'Course Price', 'Created At']
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(newCourses);
    }
  }

  render = () => {
    let error = '';
    let usersActive = [];
    let coursesActive = [];

    if(Object.keys(this.props.GetUsers).length > 0 && 'status' in this.props.GetUsers) {
      if(this.props.GetUsers.status) {
        usersActive = this.renderUsersRecords(this.props.GetUsers.data, true);
        usersActive = usersActive.filter(item => item !== undefined);
      } else {
        error = this.props.GetUsers.message || '';
      }
    }
    if(Object.keys(this.props.GetCourses).length > 0 && 'status' in this.props.GetCourses) {
      if(this.props.GetCourses.status) {
        coursesActive = this.renderCoursesRecords(this.props.GetCourses.data, true);
        coursesActive = coursesActive.filter(item => item !== undefined);
      } else {
        error = this.props.GetCourses.message || '';
      }
    }

    if(this.state.showLock){
        this.props.getUser(this.props.token);
        this.state.showLock = false;
    }

    return (
      <section>
        <Button color="success" className="float-right btn-auto" style={{padding:'2px', margin:'2px'}} onClick={()=>this.handleExportCourses(coursesActive)}>
          <FeatherIcon icon="download" size="20" className="mb-1" /> Export Courses
        </Button>
        <Button color="success" className="float-right btn-auto" style={{padding:'2px', margin:'2px'}} onClick={()=>this.handleExportUsers(usersActive)}>
          <FeatherIcon icon="download" size="20" className="mb-1" /> Export Users
        </Button>
        <h1 className="display-5">Reports</h1>
        {this.state.formSuccessMessage !== '' && <UncontrolledAlert className="text-left" color="success">
          <strong>Success:</strong> {this.state.formSuccessMessage}
        </UncontrolledAlert>}
        {error !== '' && <UncontrolledAlert className="text-left" color="danger">
          <strong>Error:</strong> {error}
        </UncontrolledAlert>}
        <div className="module-content">
          <div className="form-check">
            <label className="ml-12">
              <input type="checkbox" defaultChecked={this.state.chkbox}  id="allUsers" onChange={() => { this.setState({chkbox:!this.state.chkbox})}}/>
              <span className="red">Locked Users ?</span>
            </label>
          </div>
          <Nav className="md-tabs d-flex flex-row pointer" tabs>
            <NavItem>
              <NavLink className={this.state.activeTab == "1" ? "active" : ""}  onClick={() => this.toggleTab('1')}>
                Users Login
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={this.state.activeTab == "2" ? "active" : ""} onClick={() => this.toggleTab('2')}>
                Courses Purchased
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="md-content">
            <TabPane tabId="1" className="overflow-x">
              <BootstrapTable 
                bootstrap4
                keyField="id"
                data={usersActive}
                columns={usersColumns}
                noDataIndication="No Record Found."
                pagination={ paginationFactory() }
              />
            </TabPane>
            <TabPane tabId="2" className="overflow-x">
              <BootstrapTable 
                bootstrap4
                keyField="id"
                data={coursesActive}
                columns={coursesColumns}
                noDataIndication="No Record Found."
                pagination={ paginationFactory() }
              />
            </TabPane>
          </TabContent>
        </div>
          {this.state.userListEnable && this.userListModal()}
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    GetUsers: state.GetUsers,
    GetCourses: state.GetCourses,
  }
}

/* export class component*/
export default connect(mapStateToProps, 
  { getUsers, getCoursesByRevenue}
)(User);