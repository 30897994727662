/*  import react packages */
import React, { Component } from "react";
import { connect } from "react-redux";

/*  import Designing Constant */
import FeatherIcon from "feather-icons-react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledAlert
} from "reactstrap";

/*  import a javascript file */

/*  import consumable API functions */
import {
  getDiscount,
  updateDiscount
} from "../../../actions/modules_action/customizations";

/* Define a class Based Component */
class SubscriptionDiscountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        subscriptionDiscount: ""
      },
      uploadProperty: "",
      formSuccessMessage: "",
      formErrorMessage: "",
      accessId: 1,
      uploadError: ""
    };
    this.props.getDiscount(this.props.token, this.state.accessId);
  }

  // handleChange method is used to handle the user input's when event occured.
  handleChange = event => {
    let attribute = event.target.getAttribute("data-state");
    let value = event.target.value;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: value
      }
    });
  };

  // handleSubmit method is used to handle the form submitation.
  handleSubmit = event => {
    event.preventDefault();
    this.props.updateDiscount(
      this.props.token,
      this.state.accessId,
      this.state.form
    );
  };

  // START: Component rendering
  render = () => {
    let error = "";
    let accessData = {};

    // Used to display records
    if (
      Object.keys(this.props.GetDiscount).length > 0 &&
      "status" in this.props.GetDiscount
    ) {
      if (
        this.props.GetDiscount.status &&
        Object.keys(this.props.GetDiscount.data).length > 0
      ) {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            subscriptionDiscount: this.props.GetDiscount.data
              .subscriptionDiscount
          }
        });
        this.props.getDiscount(this.props.token);
      } else {
        this.setState({
          ...this.state,
          formErrorMessage: "Something Wrong."
        });
      }
    }

    // Used to update record.
    if (
      Object.keys(this.props.UpdateDiscount).length > 0 &&
      "status" in this.props.UpdateDiscount
    ) {
      if (this.props.UpdateDiscount.status) {
        if (this.state.formSuccessMessage === "") {
          this.setState({
            ...this.state,
            formSuccessMessage: "Subscription Discount Updated Successfully.",
            accessId: 1,
            isFormEditable: false,
            formErrorMessage: ""
          });
          this.props.getDiscount(this.props.token, this.state.accessId);
        }
      } else {
        this.setState({
          ...this.state,
          formSuccessMessage: "",
          formErrorMessage: this.props.UpdateDiscount.message || ""
        });
      }
      this.props.updateDiscount(this.props.token, this.state.accessId);
    }

    return (
      <div className="Discount-form text-medium">
        <Col>
          {this.state.formSuccessMessage !== "" && (
            <UncontrolledAlert className="text-left" color="success">
              <strong>Success:</strong> {this.state.formSuccessMessage}
            </UncontrolledAlert>
          )}
          {this.state.formErrorMessage !== "" && (
            <UncontrolledAlert className="text-left" color="danger">
              <strong>Error:</strong> {this.state.formErrorMessage}
            </UncontrolledAlert>
          )}
          {/* {this.props.error !== '' && <UncontrolledAlert className="text-left" color="danger">
                            <strong>Error:</strong> {this.props.error}
                        </UncontrolledAlert>} */}
          <Form id="Discount" onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Subscription Discount
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="number"
                      name="subscriptionDiscount"
                      data-state="subscriptionDiscount"
                      className="form-control"
                      placeholder="Discount Amount"
                      value={this.state.form.subscriptionDiscount}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button
                      color="info btn-auto"
                      className="ml-2 float-right"
                      onClick={this.handleSubmit}
                    >
                      <FeatherIcon icon="save" size="20" className="mb-1" />{" "}
                      Save
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    );
  };
  // END: Component rendering
}

const mapStateToProps = state => {
  return {
    GetDiscount: state.GetDiscount,
    UpdateDiscount: state.UpdateDiscount
  };
};

export default connect(
  mapStateToProps,
  { getDiscount, updateDiscount }
)(SubscriptionDiscountForm);
