import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";

import { API_URL } from "./../../constants";
import { getBanner, getPages } from "./../../actions/modules_action/customizations";

class Slider extends React.Component {
  constructor(props) {
    super(props);

    let loggedIn = false;
    let user_data = {};
    if (localStorage.getItem("fs_user_data") !== null) {
      user_data = JSON.parse(localStorage.getItem("fs_user_data"));
      loggedIn = true;
    }

    this.state = {
      bannerImage: "",
      accessId: 1,
      pages : []
    };
    this.props.getBanner(this.props.token, this.state.accessId);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(
      () =>
        window.scrollTo({
          behavior: "smooth",
          top: this.myRef.current.offsetTop
        }),
      2000
    );
  }

  componentWillUnmount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }

  render = () => {
    // Used to Display data items
    if (
      Object.keys(this.props.GetBanner).length > 0 &&
      "status" in this.props.GetBanner
    ) {
      if (
        this.props.GetBanner.status &&
        Object.keys(this.props.GetBanner.data).length > 0
      ) {
        //
        if (this.props.GetBanner.data.imageUrl != undefined) {
          this.setState({
            ...this.state,
            bannerImage: this.props.GetBanner.data.imageUrl
          });
          this.props.getBanner();
        }
      }
    }

    if (
      Object.keys(this.props.GetPages).length > 0 &&
      "status" in this.props.GetPages
    ) {
      if (
        this.props.GetPages.status &&
        Object.keys(this.props.GetPages.data).length > 0
      ) {
        let pages = this.props.GetPages.data;
        let newPages = [];
        for (let key in pages) {
          newPages[pages[key].slug] = pages[key].content;
        }
        this.setState({
          ...this.state,
          pages: newPages,
        });
        this.props.getPages();
      } else {
        this.setState({
          ...this.state,
          formErrorMessage: "Something Wrong."
        });
      }
    }

    const htmlString = this.state.pages['home_image_content'] ? this.state.pages['home_image_content'] : 'The FlightScope University has been established to provide a forum to educate our FlightScope customers and golf professionals around the world....';

    return (
      <div className="banner" ref={this.myRef}>
        <img
          src={`${API_URL}/${this.state.bannerImage}`}
          alt="The Last of us"
          className="img-fluid"
        />
        <Container className="top-content col-sm-10 auto-margin banner-text">
          <div className="display-4 mb-5 pt-4 text-center">
          <span className="flight-size headingtop img-content">
            {this.state.pages['home_image_content'] && this.state.pages['home_image_content'] !== null ? <div dangerouslySetInnerHTML={{ __html: this.state.pages['home_image_content'] }} /> : 'The FlightScope University has been established to provide a forum to educate our FlightScope customers and golf professionals around the world....'}
          </span>
          </div>
          <div className="row pd-t-20">
            <div className="d-flex justify-content-space-bitween col">
              <a href="/#/login" className="btn btn-info btn-auto">
                Login
              </a>
              <a href="/#/signup" className="btn btn-info btn-auto">
                Sign Up
              </a>
            </div>
          </div>
        </Container>
      </div>
    );
  };
}
const mapStateToProps = state => {
  return {
    GetBanner: state.GetBanner,
    GetPages: state.GetPages
  };
};

export default connect(
  mapStateToProps,
  { getBanner, getPages }
)(Slider);
