export const API_URL = "http://api.peakathleticsashburn.com"; // Ballflight Local node Environment

export const TLGL_API_URL = "http://localhost:6001"; // TLGL Local Node Environment
export const TLGL_REACT_API_URL = "http://localhost:3001"; // TLGL Local React Environment

//export const API_URL = 'http://bhu.thinklowgolow.com:5000'; // old Production Server
//export const API_URL = 'https://ballflightuniversity.com:5000'; // new Production Server
//export const API_URL = 'http://mcguire.chetu.com:5000'; // new staging Server

export const WP_APP = 'https://blog.ballflightuniversity.com'; // Blog (Wordpress)
export const WP_API_URL = WP_APP + '/wp-json/wp/v2';

export const HASH = '/#';
