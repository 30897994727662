/*  import react packages */
import React from 'react';
import { TLGL_REACT_API_URL } from './../../constants';

/*  define class based component */
class Survey extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <section>
        <h1 className="display-5">Surveys</h1>

        { localStorage.getItem('jwtToken') ? 
        <div className="module-content">
          <iframe src={`${TLGL_REACT_API_URL}`+'/external-jwt?token='+localStorage.getItem('jwtToken')} width="1024" height="768" frameborder="0" scrolling="yes"></iframe>
        </div>
        : 'Details not found, please contact Admin.' }
      </section>
    );
  }
}

export default Survey;